import React from "react";
import Routing from "./Routing";
import Navigation from "./components/Navigation";

export default function App() {
  return (
    <>
      <Navigation />
      <Routing />
    </>
  );
}
