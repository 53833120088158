import postsTypes from "./posts.types";

export const addPostStart = (postsData) => ({
  type: postsTypes.ADD_NEW_POST_START,
  payload: postsData,
});

export const fetchPostsStart = (filters = {}) => ({
  type: postsTypes.FETCH_POSTS_START,
  payload: filters,
});

export const setPosts = (posts) => ({
  type: postsTypes.SET_POSTS,
  payload: posts,
});

export const deletePostStart = (postID) => ({
  type: postsTypes.DELETE_POST_START,
  payload: postID,
});

export const fetchPostStart = (postID) => ({
  type: postsTypes.FETCH_POST_START,
  payload: postID,
});

export const setPost = (post) => ({
  type: postsTypes.SET_POST,
  payload: post,
});

export const updatePostStart = (postID, updatedData) => ({
  type: postsTypes.UPDATE_POST_START,
  payload: { postID, updatedData },
});

export const updatePostSuccess = () => ({
  type: postsTypes.UPDATE_POST_SUCCESS,
});

export const updatePostError = (error) => ({
  type: postsTypes.UPDATE_POST_ERROR,
  payload: error,
});
export const searchPostsStart = (searchTerm) => ({
  type: postsTypes.SEARCH_POSTS_START,
  payload: searchTerm,
});

export const increaseSavesStart = (postID) => ({
  type: postsTypes.INCREASE_SAVES_START,
  payload: postID,
});

export const decreaseSavesStart = (postID) => ({
  type: postsTypes.DECREASE_SAVES_START,
  payload: postID,
});

export const adjustSavesSuccess = () => ({
  type: postsTypes.ADJUST_SAVES_SUCCESS,
});

export const adjustSavesError = (error) => ({
  type: postsTypes.ADJUST_SAVES_ERROR,
  payload: error,
});

//UPDATE OR REMOVE THE POST MENU FOR PLANNER
export const updatePostMenuStart = (postData) => ({
  type: postsTypes.UPDATE_POST_MENU_START,
  payload: postData,
});
export const updatePostMenuSuccess = (postData) => ({
  type: postsTypes.UPDATE_POST_MENU_SUCCESS,
  payload: postData,
});
export const removePostMenuItemStart = (payload) => ({
  type: postsTypes.REMOVE_POST_MENU_ITEM_START,
  payload: payload,
});
export const removePostMenuItemSuccess = (postData) => ({
  type: postsTypes.REMOVE_POST_MENU_ITEM_SUCCESS,
  payload: postData,
});