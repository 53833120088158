import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Homepage from "./pages/Homepage";
import Account from "./pages/Account";
import Page404 from "./pages/Page404";
import { PostDetails } from "./pages/PostPage";

export default function Routing() {
  return (
    <div>
      <Suspense fallback="Loading">
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route exact path="/account" element={<Account />} />
          <Route exact path="/:postID" element={<PostDetails />} />
          <Route exact path="*" element={<Page404 />} />
        </Routes>
      </Suspense>
    </div>
  );
}
