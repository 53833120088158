import { auth, firestore } from "./../../firebase/utils";
//RESET PASSWORD
export const handleResetPasswordAPI = (email) => {
  const config = {
    url: "https://listeat.ro/auth",
  };

  return new Promise((resolve, reject) => {
    auth
      .sendPasswordResetEmail(email, config)
      .then(() => {
        resolve();
      })
      .catch(() => {
        const err = ["Email not found. Please try again."];
        reject(err);
      });
  });
};

//CHECK EMAIL EXISTS
export const getUserByEmail = async (email) => {
  const userRef = firestore
    .collection("users")
    .where("email", "==", email)
    .limit(1);
  const userSnapshot = await userRef.get();

  if (!userSnapshot.empty) {
    const userData = userSnapshot.docs[0].data();
    return { exists: true, ...userData };
  } else {
    return { exists: false };
  }
};
//UPDATE USER PROFILE
export const handleUpdateUserProfile = (userData) => {
  const { userID, isOnline, lastSeen, ...updateData } = userData;

  return new Promise((resolve, reject) => {
    firestore
      .collection("users")
      .doc(userID)
      .update({ ...updateData, isOnline, lastSeen })
      .then(() => resolve())
      .catch((err) => reject(err));
  });
};
//BLOCK USER ACTIONS
export const handleUpdateBlockList = (userID, targetID) => {
  return new Promise((resolve, reject) => {
    const userDocRef = firestore.collection("users").doc(userID);

    userDocRef
      .get()
      .then((userDoc) => {
        if (!userDoc.exists) {
          throw new Error("User document does not exist");
        }
        let blocked = userDoc.data().blocked || [];
        if (blocked.includes(targetID)) {
          blocked = blocked.filter((id) => id !== targetID);
        } else {
          blocked.push(targetID);
        }
        return userDocRef.update({ blocked: blocked }).then(() => blocked);
      })
      .then((updatedBlocked) => resolve(updatedBlocked))
      .catch((err) => reject(err));
  });
};
//UPDATE SUBSCRIPTION PRICE
export const handleUpdateSubscriptionPrice = async (
  userID,
  subscriptionPrice
) => {
  try {
    const userRef = firestore.collection("users").doc(userID);
    await userRef.update({ subscriptionPrice });
  } catch (error) {
    throw new Error("Error updating subscription price");
  }
};
//SAVE AND REMOVE POST
export const handleSavePost = async (userID, postID) => {
  const userDoc = firestore.collection("users").doc(userID);
  const doc = await userDoc.get();

  if (doc.exists) {
    const userData = doc.data();
    const currentSavedPosts = userData.savedPosts || [];
    const isPostAlreadySaved = currentSavedPosts.includes(postID);

    if (!isPostAlreadySaved) {
      const updatedSavedPosts = [...currentSavedPosts, postID];

      return userDoc.update({ savedPosts: updatedSavedPosts });
    }
  } else {
    console.error("User document does not exist");
  }
};
export const handleRemovePost = async (userID, postID) => {
  const userDoc = firestore.collection("users").doc(userID);
  const doc = await userDoc.get();

  if (doc.exists) {
    const userData = doc.data();
    const updatedSavedPosts = userData.savedPosts.filter(
      (savedPostID) => savedPostID !== postID
    );

    await userDoc.update({ savedPosts: updatedSavedPosts });
    return updatedSavedPosts;
  } else {
    console.error("User document does not exist");
    return [];
  }
};
