import React, { useEffect, useState } from "react";
import { deletePostStart, updatePostStart } from "../redux/posts/posts.actions";
import { useDispatch } from "react-redux";
import { Modal } from "./Modal";
import { HiPencil } from "react-icons/hi";

export default function EditPost({ post, onClose }) {
  const dispatch = useDispatch();
  const [type, setType] = useState(post.type || 1);

  // BLOG FORM
  const [blogContent, setBlogContent] = useState(post.content || []);
  const [contentType, setContentType] = useState("");
  const [contentData, setContentData] = useState("");

  // MODAL
  const [hideModal, setHideModal] = useState(true);
  const toggleModal = () => setHideModal(!hideModal);
  const configModal = {
    hideModal,
    toggleModal,
  };

  useEffect(() => {
    if (post) {
      setType(post.type || 1);
      setBlogContent(post.content || []);
    }
  }, [post]);

  // ADD CONTENT TO BLOG
  const handleAddContent = () => {
    setBlogContent([...blogContent, { type: contentType, data: contentData }]);
    setContentType("");
  };

  // SUBMIT POST
  const handleSubmit = async (e) => {
    e.preventDefault();

    const blogPostData = {
      type,
      content: blogContent,
    };
    dispatch(updatePostStart(post.documentID, blogPostData));
    toggleModal();
  };

  return (
    <>
      <button className="btnLogin absolute" onClick={() => toggleModal()}>
        <HiPencil />
      </button>
      <Modal {...configModal}>
        <h2 className="formHeader">Edit {post.name}</h2>
        <form onSubmit={handleSubmit}>
          <div className="blogForm">
            <button type="submit">Update</button>
          </div>
          <button
            className="btnTertiary"
            type="button"
            onClick={() => toggleModal()}
          >
            Cancel
          </button>
          <br />
          <br />
          <button
            className="btnTertiary red"
            type="button"
            onClick={() =>
              dispatch(deletePostStart(post.documentID)) && toggleModal()
            }
          >
            Delete
          </button>
        </form>
      </Modal>
    </>
  );
}
