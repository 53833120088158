import React from "react";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { googleSignInStart } from "../redux/user/user.actions";
import { Link } from "react-router-dom";
import Results from "../Results";
import { HiOutlineUserCircle } from "react-icons/hi";

const currentUserSelector = (state) => state.user.currentUser;

const mapState = createSelector([currentUserSelector], (currentUser) => ({
  currentUser,
}));

function Homepage() {
  const dispatch = useDispatch();
  const { currentUser } = useSelector(mapState);

  const handleGoogleSignIn = () => {
    dispatch(googleSignInStart());
  };

  return (
    <>
      <div className="app">
        <Results />
      </div>
      <footer>
        <ul>
          {currentUser ? (
            <li>
              <Link to="/account">
                <button className="btnList">
                  <HiOutlineUserCircle /> My account
                </button>
              </Link>
            </li>
          ) : (
            <li onClick={handleGoogleSignIn}>
              <button className="btnList">
                <HiOutlineUserCircle /> Login
              </button>
            </li>
          )}
          <br />
          <li>
            <small>Copyright &copy; Manifest999</small>
          </li>
        </ul>
      </footer>
    </>
  );
}

export default Homepage;
