import React from "react";
import "./Navigation.scss";

export default function Navigation() {
  return (
    <>
      <nav>
        <button>MANIFEST999</button>
      </nav>
    </>
  );
}
