import postsTypes from "./posts.types";
import postTypes from "./posts.types";

const INITIAL_STATE = {
  posts: [],
  post: {},
  isViewed: 0,
};

const postsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case postTypes.SET_POSTS:
      return {
        ...state,
        posts: action.payload,
      };
    case postsTypes.SET_POST:
      return {
        ...state,
        post: action.payload,
      };

    //UPDATE POST
    case postsTypes.UPDATE_POST_SUCCESS:
      return {
        ...state,
        posts: action.payload,
      };
    case postsTypes.UPDATE_POST_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    //UPDATE OR REMOVE POST MENU
    case postsTypes.UPDATE_POST_MENU_SUCCESS:
      return {
        ...state,
        post: action.payload,
      };
    case postsTypes.REMOVE_POST_MENU_ITEM_SUCCESS:
      return {
        ...state,
          post: action.payload,
      };
    case postsTypes.ADJUST_SAVES_SUCCESS:
      return {
        ...state,
      };
    case postsTypes.ADJUST_SAVES_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default postsReducer;
