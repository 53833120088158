import React from "react";
import "./Modal.scss";

export function Modal({ children, hideModal, extenderClass, index, id }) {
  if (hideModal) return null;
  return (
    <React.Fragment key={index}>
      <div className={`modal ${extenderClass}`}>
        <div>{children}</div>
      </div>
    </React.Fragment>
  );
}
