const postsTypes = {
  //ADD POST
  ADD_NEW_POST_START: "ADD_NEW_POST_START",

  //SET AND FETCH POSTS
  FETCH_POSTS_START: "FETCH_POSTS_START",
  SET_POSTS: "SET_POSTS",
  FETCH_POST_START: "FETCH_POST_START",
  SET_POST: "SET_POST",

  //DELETE POST
  DELETE_POST_START: "DELETE_POST_START",

  //UPDATE POST
  UPDATE_POST_START: "UPDATE_POST_START",
  UPDATE_POST_SUCCESS: "UPDATE_POST_SUCCESS",
  UPDATE_POST_ERROR: "UPDATE_POST_ERROR",

  //UPDATE OR REMOVE POST MENU
  UPDATE_POST_MENU_START: "UPDATE_POST_MENU_START",
  UPDATE_POST_MENU_SUCCESS: "UPDATE_POST_MENU_SUCCESS",
  REMOVE_POST_MENU_ITEM_START: "REMOVE_POST_MENU_ITEM_START",
  REMOVE_POST_MENU_ITEM_SUCCESS: "REMOVE_POST_MENU_ITEM_SUCCESS",

  //SEARCH POST
  SEARCH_POSTS_START: "SEARCH_POSTS_START",

  //COUNT SAVED
  INCREASE_SAVES_START: "INCREASE_SAVES_START",
  DECREASE_SAVES_START: "DECREASE_SAVES_START",
  ADJUST_SAVES_SUCCESS: "ADJUST_SAVES_SUCCESS",
  ADJUST_SAVES_ERROR: "ADJUST_SAVES_ERROR",
};

export default postsTypes;
