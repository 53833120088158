import React, { useState } from "react";
import { addPostStart } from "../redux/posts/posts.actions";
import { useDispatch } from "react-redux";
import { Modal } from "./Modal";
import { HiOutlineTrash } from "react-icons/hi";

export default function AddPost() {
  const dispatch = useDispatch();
  const [type, setType] = useState(1);
  const [blogContents, setBlogContents] = useState([]);
  const [contentType, setContentType] = useState("");
  const [contentData, setContentData] = useState("");
  //MODAL
  const [hideModal, setHideModal] = useState(true);
  const toggleModal = () => setHideModal(!hideModal);
  const configModal = { hideModal, toggleModal };
  //LIST
  const [listItems, setListItems] = useState([]);
  const handleAddListItem = () => {
    if (contentData) {
      setListItems([...listItems, contentData]);
      setContentData("");
    }
  };
  //BLOG
  const handleAddContent = () => {
    const content =
      contentType === "ul"
        ? { type: "ul", data: listItems }
        : { type: contentType, data: contentData };
    setBlogContents([...blogContents, content]);
    setContentData("");
    setContentType("");
    setListItems([]);
  };

  //REMOVE BLOG CONTENT
  const removeContent = (indexToRemove) => {
    setBlogContents(blogContents.filter((_, index) => index !== indexToRemove));
  };

  //SUBMIT
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      type,
      contents:
        type === 1 ? blogContents : [{ type: "song", data: contentData }],
    };
    dispatch(addPostStart(payload));
    toggleModal();
  };

  return (
    <>
      <button onClick={toggleModal}>Add</button>
      <Modal {...configModal}>
        <h2 className="formHeader">
          Add{" "}
          <select
            value={type}
            onChange={(e) => setType(parseInt(e.target.value))}
            required
          >
            <option value={1}>blog</option>
            <option value={2}>song</option>
          </select>
        </h2>
        <form onSubmit={handleSubmit}>
          {type === 1 ? (
            <>
              <div className="blogForm">
                {!contentType && (
                  <div>
                    <label>Add Content:</label>
                    <select
                      value={contentType}
                      onChange={(e) => setContentType(e.target.value)}
                    >
                      <option value="">Select content type</option>
                      <option value="image">Image</option>
                      <option value="header">Header</option>
                      <option value="paragraph">Paragraph</option>
                      <option value="ul">List</option>
                    </select>
                  </div>
                )}
                {contentType === "ul" && (
                  <>
                    <div>
                      <label>List Item:</label>
                      <input
                        type="text"
                        value={contentData}
                        onChange={(e) => setContentData(e.target.value)}
                      />
                      <button
                        type="button"
                        onClick={handleAddListItem}
                        disabled={!contentData.length}
                      >
                        Add list item
                      </button>
                    </div>
                    <ul>
                      {listItems.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </>
                )}
                {contentType && contentType !== "ul" && (
                  <div>
                    <label>
                      {contentType === "image"
                        ? "Image URL"
                        : contentType.charAt(0).toUpperCase() +
                          contentType.slice(1)}
                      :
                    </label>
                    <input
                      type="text"
                      value={contentData}
                      onChange={(e) => setContentData(e.target.value)}
                    />
                  </div>
                )}
                {contentType && (
                  <>
                    <button
                      type="button"
                      onClick={handleAddContent}
                      disabled={
                        contentType === "ul"
                          ? listItems.length === 0
                          : !contentData.length
                      }
                    >
                      Add {contentType}
                    </button>
                    <button
                      className="btnTertiary"
                      onClick={() => setContentType("")}
                    >
                      Cancel
                    </button>
                  </>
                )}
                <br />
                <h3 className="textFlex">Preview</h3>
                <div className="blogContent">
                  {blogContents.map((item, index) => (
                    <React.Fragment key={index}>
                      {item.type === "image" ? (
                        <div className="blogContentItem">
                          <img
                            src={item.data}
                            alt={`Blog content ${index}`}
                            style={{ width: "100%" }}
                          />
                          <button
                            type="button"
                            className="blogContentRemoveBtn"
                            onClick={() => removeContent(index)}
                          >
                            <HiOutlineTrash color="red" />
                          </button>
                        </div>
                      ) : item.type === "header" ? (
                        <div className="blogContentItem">
                          <h2>{item.data}</h2>
                          <button
                            type="button"
                            className="blogContentRemoveBtn"
                            onClick={() => removeContent(index)}
                          >
                            <HiOutlineTrash color="red" />
                          </button>
                        </div>
                      ) : item.type === "paragraph" ? (
                        <div className="blogContentItem">
                          <p>{item.data}</p>
                          <button
                            type="button"
                            className="blogContentRemoveBtn"
                            onClick={() => removeContent(index)}
                          >
                            <HiOutlineTrash color="red" />
                          </button>
                        </div>
                      ) : (
                        <div className="blogContentItem">
                          <ul>
                            {item.data.map((li, idx) => (
                              <li key={idx}>{li}</li>
                            ))}
                          </ul>
                          <button
                            type="button"
                            className="blogContentRemoveBtn"
                            onClick={() => removeContent(index)}
                          >
                            <HiOutlineTrash color="red" />
                          </button>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                  <br />
                </div>
                <br />
                <button type="submit" disabled={blogContents.length < 1}>
                  Submit
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="songForm">
                <label>Song URL:</label>
                <input
                  type="text"
                  placeholder="Enter song URL"
                  value={contentData}
                  onChange={(e) => setContentData(e.target.value)}
                  required
                />
              </div>
              <button type="submit" disabled={!contentData}>
                Submit
              </button>
            </>
          )}
          <button className="btnTertiary" type="button" onClick={toggleModal}>
            Cancel
          </button>
        </form>
      </Modal>
    </>
  );
}
