const userTypes = {
  EMAIL_VERIFICATION_START: "EMAIL_VERIFICATION_START",
  EMAIL_VERIFICATION_SUCCESS: "EMAIL_VERIFICATION_SUCCESS",
  EMAIL_VERIFICATION_ERROR: "EMAIL_VERIFICATION_ERROR",
  VERIFY_EMAIL_START: 'VERIFY_EMAIL_START',
  
  SIGN_IN_START: "SIGN_IN_START",
  SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
  SIGN_IN_ERROR: "SIGN_IN_ERROR",

  SIGN_UP_USER_START: "SIGN_UP_USER_START",
  SIGN_UP_USER_SUCCESS: "SIGN_UP_USER_SUCCESS",
  SIGN_UP_USER_ERROR: "SIGN_UP_USER_ERROR",

  SIGN_OUT_USER_START: "SIGN_OUT_USER_START",
  SIGN_OUT_USER_SUCCESS: "SIGN_OUT_USER_SUCCESS",
  SIGN_OUT_USER_ERROR: "SIGN_OUT_USER_ERROR",

  RESET_PASSWORD_START: "RESET_PASSWORD_START",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_ERROR: "RESET_PASSWORD_ERROR",

  SET_LOADING: "SET_LOADING",
  CHECK_USER_SESSION: "CHECK_USER_SESSION",
  GOOGLE_SIGN_IN_START: "GOOGLE_SIGN_IN_START",

  USER_ERROR: "USER_ERROR",
  RESET_USER_STATE: "RESET_USER_STATE",

  UPDATE_USER_PROFILE_START: "UPDATE_USER_PROFILE_START",
  UPDATE_USER_PROFILE_SUCCESS: "UPDATE_USER_PROFILE_SUCCESS",
  UPDATE_USER_PROFILE_ERROR: "UPDATE_USER_PROFILE_ERROR",

  UPDATE_BLOCK_LIST_START: "UPDATE_BLOCK_LIST_START",
  UPDATE_BLOCK_LIST_SUCCESS: "UPDATE_BLOCK_LIST_SUCCESS",
  UPDATE_BLOCK_LIST_ERROR: "UPDATE_BLOCK_LIST_ERROR",

  SAVE_POST_START: "SAVE_POST_START",
  SAVE_POST_SUCCESS: "SAVE_POST_SUCCESS",
  SAVE_POST_ERROR: "SAVE_POST_ERROR",

  REMOVE_POST_START: "REMOVE_POST_START",
  REMOVE_POST_SUCCESS: "REMOVE_POST_SUCCESS",
  REMOVE_POST_ERROR: "REMOVE_POST_ERROR",

  JOYRIDE_TOUR_START: "JOYRIDE_TOUR_START",

  UPDATE_SUBSCRIPTION_PRICE_START: 'UPDATE_SUBSCRIPTION_PRICE_START',
  UPDATE_SUBSCRIPTION_PRICE_SUCCESS: 'UPDATE_SUBSCRIPTION_PRICE_SUCCESS',
  UPDATE_SUBSCRIPTION_PRICE_ERROR: 'UPDATE_SUBSCRIPTION_PRICE_ERROR',
};

export default userTypes;
