import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { useNavigate } from "react-router-dom";
import AddPost from "../components/AddPost";
import { signOutUserStart } from "../redux/user/user.actions";

const currentUserSelector = (state) => state.user.currentUser;
const mapState = createSelector([currentUserSelector], (currentUser) => ({
  currentUser,
}));

export default function Account() {
  const dispatch = useDispatch();
  const { currentUser } = useSelector(mapState);
  const navigate = useNavigate();

  const signOut = () => {
    dispatch(signOutUserStart());
    navigate("/");
  };

  return (
    <div className="page">
      <h1>
        Welcome back
        <br />
        {currentUser.displayName}
      </h1>
      {currentUser.userRoles && currentUser.userRoles.includes("admin") ? (
        <AddPost />
      ) : null}

      <button onClick={() => signOut()}>Iesire cont</button>
      <button className="btnTertiary" onClick={() => navigate(-1)}>
        Inapoi
      </button>
    </div>
  );
}
