import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
//REDUX
import { useDispatch, useSelector } from "react-redux";
import { fetchPostStart, setPost } from "../redux/posts/posts.actions";
import { createSelector } from "reselect";

const currentUserSelector = (state) => state.user.currentUser;
const postsSelector = (state) => state.postsData.posts;
const postSelector = (state) => state.postsData.post;

const mapState = createSelector(
  [currentUserSelector, postsSelector, postSelector],
  (currentUser, posts, post) => ({
    currentUser,
    posts,
    post,
  })
);

export const PostDetails = () => {
  return <PostPage />;
};

export function PostPage() {
  const dispatch = useDispatch();
  const { post } = useSelector(mapState);
  const { postID } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchPostStart(postID));
    return () => {
      dispatch(setPost({}));
    };
  }, [dispatch, postID]);

  //BACK BUTTON
  const handleBackButtonClick = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

  return (
    <div className="page">
      <div className="pagePost">
        <>
          {post.contents &&
            post.contents.map((item, contentIndex) => (
              <div key={contentIndex}>
                {item.type === "image" ? (
                  <img
                    src={item.data}
                    alt={`Blog content ${contentIndex}`}
                    style={{ width: "100%", maxHeight: "300px" }}
                  />
                ) : item.type === "header" ? (
                  <h1>{item.data}</h1>
                ) : item.type === "ul" ? (
                  <ul>
                    {item.data.map((listItem, listIndex) => (
                      <li key={listIndex}>{listItem}</li>
                    ))}
                  </ul>
                ) : (
                  <p>{item.data}</p>
                )}
              </div>
            ))}
        </>
        <br />
        <button className="btnTertiary" onClick={handleBackButtonClick}>
          Back
        </button>
        <br />
        <br />
      </div>
    </div>
  );
}
