import React, { useState, useEffect } from "react";
import { fetchPostsStart } from "./redux/posts/posts.actions";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { TbShare3 } from "react-icons/tb";
import { Link } from "react-router-dom";
import EditPost from "./components/EditPost";
import { HiOutlineSearch, HiOutlineShare } from "react-icons/hi";

const currentUserSelector = (state) => state.user.currentUser;
const postsSelector = (state) => state.postsData.posts;

const mapState = createSelector(
  [currentUserSelector, postsSelector],
  (currentUser, posts) => ({
    currentUser,
    posts,
  })
);

export default function Results({ postType }) {
  const dispatch = useDispatch();
  const { currentUser, posts } = useSelector(mapState);
  const { data: postsData = [] } = posts || {};

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredPosts, setFilteredPosts] = useState(postsData);

  useEffect(() => {
    dispatch(fetchPostsStart());
  }, [dispatch]);

  useEffect(() => {
    setFilteredPosts(postsData); // Update filtered posts when postsData changes
  }, [postsData]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const submitSearch = () => {
    const results = postsData.filter((post) => {
      const firstHeader = post.contents.find(
        (content) => content.type === "header"
      );
      return (
        firstHeader &&
        firstHeader.data.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    setFilteredPosts(results);
  };

  if (filteredPosts.length === 0) {
    return <h3>No posts found</h3>;
  }

  return (
    <div className="page">
      <div className="searchBar">
        <input
          type="text"
          id="search"
          placeholder="Search posts"
          value={searchQuery}
          onChange={handleSearch}
        />
        <button onClick={submitSearch}>
          <HiOutlineSearch />
        </button>
      </div>

      <table id="myTable">
        <tbody>
          {filteredPosts.map((post, index) => {
            if (post.type === 2) return null;

            const shareBlog = () => {
              if (navigator.share) {
                const firstHeader =
                  post.contents.find((content) => content.type === "header")
                    ?.data || "Blog Post";
                navigator
                  .share({
                    title: firstHeader,
                    text: `${firstHeader} - manifest999.com`,
                    url: `https://manifest999.com/${post.documentID}`,
                  })
                  .then(() => console.log("Share successful"))
                  .catch((error) => console.log("Share error", error));
              }
            };

            const firstHeader = post.contents.find(
              (content) => content.type === "header"
            );
            const firstImage = post.contents.find(
              (content) => content.type === "image"
            );
            const firstParagraph = post.contents.find(
              (content) => content.type === "paragraph"
            );
            return (
              <tr key={index}>
                <td>
                  <button className="card">
                    {!currentUser ? null : <EditPost post={post} />}
                    <React.Fragment>
                      <div className="cardText">
                        <Link to={post.documentID}>
                          {firstImage ? (
                            <img src={firstImage.data} alt="Manifest999" />
                          ) : (
                            <img
                              src="/assets/placeholderImg.webp"
                              alt="Manifest999"
                            />
                          )}
                        </Link>
                        {firstHeader && (
                          <h3>
                            <Link to={post.documentID}>{firstHeader.data}</Link>
                            <button onClick={shareBlog}>
                              <TbShare3 />
                            </button>
                          </h3>
                        )}
                        {firstParagraph && <p>{firstParagraph.data}</p>}
                      </div>
                      {/* <button onClick={shareBlog}>
                        <TbShare3 />
                        Share
                      </button> */}
                    </React.Fragment>
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
